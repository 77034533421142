import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ButtonBrand } from "Components/Button";
import { pushEvent } from "modules/google-tag-manager";
import LetsConnect from "Components/LetsConnect";

const AgencyRegistrationComplete = () => {
  useEffect(() => {
    pushEvent("user_registered", { userType: "Agent" });
  }, []);

  return (
    <div className="container">
      <div className="flex">
        <div className="w-full mt-4 p-12">
          <span className="text-center block text-5xl">
            <i className="fal fa-envelope-open-text"></i>
          </span>
          <h1 id="user-registered" className="text-center">
            Congratulations for registering on EDGE Agency
          </h1>
          <p className="text-center mt-10 text-lg">
            You are nearly there! An e-mail has just been sent to you.
          </p>
          <p className="text-center text-lg">
            Please find our e-mail and verify your EDGE Agency account.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-globe-americas block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Discover 19,000 venues</h3>
            <p>
              We pride ourselves on our venue knowledge and have created an extensive high quality database to meet the
              needs of meeting and event planners covering 125 destinations in 45 countries.
            </p>
            <ButtonBrand Element={Link} to={"/search"} classes="w-full mb-5">
              {"Find a venue"}
            </ButtonBrand>
          </div>
        </div>
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-lightbulb-on block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Inspiration</h3>
            <p>
              Keep up to date with all the latest industry news. With trends, expert insights and top tip features, EDGE
              Venues is your central hub of information for meetings and events.
            </p>
            <ButtonBrand
              Element={Link}
              to={"/inspiration"}
              classes="w-full mb-5"
            >
              {"Read articles"}
            </ButtonBrand>
          </div>
        </div>
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-tags block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Commission offers</h3>
            <p>
              Ensure you make the most out of your booking not only for your client, but for your own bottom line by
              checking out the current commission deals on offer.
            </p>
            <ButtonBrand
              Element={Link}
              to={"/commission-offers"}
              classes="w-full mb-5"
            >
              {"View commission offers"}
            </ButtonBrand>
          </div>
        </div>
      </div>

      <LetsConnect />
    </div>
  );
};

export default AgencyRegistrationComplete;
