import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Select } from "../../../../ui";
import { connect } from "react-redux";
import { companyObject, corporateAccountObject, isRoleEdgeAdmin } from "../../../../modules/permissions";
import {
  fetchAgenciesForSelect,
  fetchCashFlowReport,
  fetchCompanies,
  fetchCompaniesForEvents,
  unloadCashFlowReport,
  unloadCompanyList,
} from "../../../../redux/actions";
import { token } from "../../../../modules/auth";
import CashFlowReportTable from "../../../../Components/corporate/reports/CashFlowReportTable";
import AgencyReportFilters from "../../../../Components/corporate/reports/AgencyReportFilters";
import { ButtonPrimary, ButtonSpan } from "../../../../Components/Button";
import classnames from "classnames";
import Loading from "../../../../Components/Loading";
import * as ROUTES from "../../../../Router/corporate_routes";
import { removeEmptySearchParams } from "../../../../utils";
import { saveData } from "../../../../services/downloadFile";

const getYears = (startYear, endYear) => {
  let years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

const ToggleReportType = ({ reportType, calendarYearOnClick, financialYearOnClick }) => {
  return <div className="mb-2 xxl:mb-0">
    <div className="flex text-xs min-h-9.5">
      <ButtonSpan
        className={classnames(
          "inline-block flex items-center px-3 border border-grey-md",
          { "opacity-50": reportType === "calendarYear", "bg-brand": reportType !== "calendarYear" },
        )}
        onClick={financialYearOnClick}
      >
        Financial Year
      </ButtonSpan>
      <ButtonSpan
        className={classnames(
          "inline-block flex items-center px-3 border border-grey-md border-r-0",
          { "opacity-50": reportType !== "calendarYear", "bg-brand": reportType === "calendarYear" },
        )}
        onClick={calendarYearOnClick}
      >
        Calendar Year
      </ButtonSpan>
    </div>
  </div>;
};

const CashFlowReportContainer = ({
                                   isRoleEdgeAdmin,
                                   agenciesForSelect,
                                   fetchAgenciesForSelect,
                                   token,
                                   selectedAgencies,
                                   companies,
                                   fetchCompaniesForEvents,
                                   fetchCashFlowReport,
                                   isAgency,
                                   fetchCompanies,
                                   isFetchingCashFlowReport,
                                   cashFlowReport,
                                   unloadCashFlowReport,
                                   companyAccount,
                                   unloadCompanyList
                                 }) => {
  const currentYear = new Date().getFullYear();
  const [agency, setAgency] = useState();
  const [year, setYear] = useState(currentYear);
  const [reportType, setReportType] = useState("calendarYear");
  const [company, setCompany] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(isFetchingCashFlowReport);
  const availableYears = getYears(2015, 2030);
  const [queryParams, setQueryParams] = useState({
    calendarYear: currentYear,
    reportType: "calendarYear",
    company: null,
  });

  const updateQueryParams = (params) => {
    setQueryParams(prevParams => ({
      ...prevParams,
      ...params,
    }));
  };

  useEffect(() => {
    fetchCompaniesForEvents(token);
    if (selectedAgencies.length === 1) {
      setAgency(selectedAgencies[0]);
    } else {
      setAgency(null);
    }
    if (companyAccount && companyAccount.agency) {
      setAgency(companyAccount.agency);
    }
  }, [selectedAgencies]);

  useEffect(() => {
    if (isRoleEdgeAdmin && agency) {
      fetchCompanies(token, null, { agencyId: agency }, true);
    }
  }, [isRoleEdgeAdmin, company, agency]);

  useEffect(() => {
    if (isRoleEdgeAdmin) {
      fetchAgenciesForSelect(token, true);
    }
    if (companyAccount) {
      setAgency(companyAccount?.agency);
    }
    return () => {
      unloadCashFlowReport();
    };
  }, []);

  useEffect(() => {
    if (company) {
      setQueryParams({
        "company": company,
        "agency": agency?.id,
      });
    } else {
      setQueryParams({
        "agency": agency?.id,
      });
    }
  }, [company, agency]);

  const runReport = useCallback(() => {
    if (!queryParams.calendarYear) {
      queryParams.calendarYear = currentYear;
    }
    return fetchCashFlowReport(token, queryParams);
  }, [agency, token, queryParams]);

  const runCashFlowReportExport = useCallback(async () => {
    setIsExporting(true);
    try {
      if (!queryParams.calendarYear) {
        queryParams.calendarYear = 2024;
      }
      let queryFilters = new URLSearchParams(queryParams);
      removeEmptySearchParams(queryFilters);
      let queryString = queryFilters.toString();
      let response = await axios.get(
        window.API + ROUTES.API.AGENCY.REPORTS.CASH_FLOW_REPORT_EXPORT + "?" + queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        "csv",
        response.data,
        `cash-flow-report.csv`,
      );
    } catch (e) {
      console.error(e);
    }
    setIsExporting(false);
  }, [agency, token, queryParams]);

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setYear(selectedYear);
    updateQueryParams({ calendarYear: selectedYear });
  };

  const handleReportTypeChange = (type) => {
    setReportType(type);
    updateQueryParams({ reportType: type });
  };

  const resetFilters = () => {
    unloadCashFlowReport();
    unloadCompanyList();
    setCompany(null);
    setYear(currentYear);
    setReportType("calendarYear");
    setQueryParams({
      calendarYear: currentYear,
      reportType: "calendarYear",
      company: null,
    });
    window.localStorage.removeItem("companyParams");
  };

  return (
    <div className="min-w-full">
      <h1 className="my-4">
        Cash flow report
      </h1>

      <AgencyReportFilters
        agencies={agenciesForSelect}
        isRoleEdgeAdmin={isRoleEdgeAdmin}
        companies={companies}
        resetFilters={resetFilters}
        runReport={runReport}
        fetchCompanies={fetchCompanies}
        token={token}
        shouldDisableSubmit={isFetchingCashFlowReport}
        additionalFilters={
          <>
            <ToggleReportType
              reportType={reportType}
              financialYearOnClick={() => handleReportTypeChange("financial")}
              calendarYearOnClick={() => handleReportTypeChange("calendarYear")}
            />
            <div className="mb-2 xxl:mb-0 mr-2">
              <Select
                name="calendarYear"
                label="Year"
                component={Select}
                // 2018 is initial commit - current year + 5
                options={getYears(2018, new Date().getFullYear() + 5).map(year => ({ text: year, value: year }))}
                wrapperClassName="mb-0"
                input={{
                  onChange: handleYearChange,
                  value: year,
                }}
                meta={{}}
              />
            </div>
          </>
        }
        queryParams={queryParams}
        setQueryParams={updateQueryParams}
      />

      <div className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:mb-0 lg:items-end bg-white`}>
        {isFetchingCashFlowReport ? (
          <div className={"row"}>
            <Loading />
          </div>
        ) : (
          <CashFlowReportTable
            cashFlowReport={cashFlowReport}
          />
        )}
        {!isFetchingCashFlowReport && (
          <div className={"row mb-2"}>
            <div className={"col flex"}>
              <ButtonPrimary classes={"mt-4"} onClick={() => runCashFlowReportExport()} disabled={isExporting}>
                Export to CSV
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  let companyAccount = Object.entries(companyObject(state.auth)).length > 0 ? companyObject(state.auth) : state.company?.company;
  let companies = state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [];
  let isEdgeAdmin = isRoleEdgeAdmin(state.auth);
  if (isEdgeAdmin) {
    companies = state.companyList.companies ? state.companyList.companies : [];
  }
  return {
    corporateAccount,
    ...state.selectedAgencies,
    ...state.agencyList,
    ...state.cashFlowReport,
    companyAccount,
    companies: companies || [],
    isRoleEdgeAdmin: isEdgeAdmin,
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchAgenciesForSelect,
  fetchCompaniesForEvents,
  fetchCompanies,
  fetchCashFlowReport,
  unloadCashFlowReport,
  unloadCompanyList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashFlowReportContainer);
