import React from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

class WarningNote extends React.Component {
    static propTypes = {
        children: node,
        className: string,
    };

    render() {
        if (!this.props.children) return null;
        return (
            <div
                className={classNames(
                    "bg-primary text-white p-3",
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

export default WarningNote;
