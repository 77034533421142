import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAgencyFinancialsFromEvent, unloadAgencyFinancials } from "../../../../../redux/actions";
import { token } from "../../../../../modules/auth";
import * as AGENCY_ROUTES from "../../../../../Router/corporate_routes";
import Loading from "../../../../../Components/Loading";
import { currencyCodesWhichShouldHaveFinancials } from "../../../../../utils";

let FinancialsComponent = ({
                             event,
                             token,
                             fetchAgencyFinancialsFromEvent,
                             unloadAgencyFinancials,
                             agencyFinancials,
                             currencyCode,
                             agencyId,
                             isFetchingAgencyFinancials,
                           }) => {
  const [agencyFinancialsUsed, setAgencyFinancialsUsed] = useState();

  useEffect(() => {
    return () => {
      unloadAgencyFinancials();
    };
  }, []);

  useEffect(() => {
    fetchAgencyFinancialsFromEvent(event.id, token);
  }, [currencyCode]);

  useEffect(() => {
    if (agencyFinancials && currencyCode) {
      setAgencyFinancialsUsed(agencyFinancials.find(agencyFinancial => {
        let currencyCodeToUse = currencyCodesWhichShouldHaveFinancials.includes(currencyCode) ? currencyCode : "GBP";
        return agencyFinancial?.currency?.currency_code == currencyCodeToUse;
      }));
    }
  }, [currencyCode, agencyFinancials]);

  if (isFetchingAgencyFinancials) {
    return <Loading />;
  }

  if (!agencyFinancialsUsed) {
    return <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
      <p className={"mb-0"}><i className="fas text-2lg fa-exclamation-triangle mr-3" />
        Financials details are missing for this currency. If the details for this currency are different please go and add them <Link
          target={"_blank"} rel={"noopener noreferrer"}
          to={AGENCY_ROUTES.FRONTEND.AGENCY.FINANCIALS.EDIT.replace(":agencyId", agencyId)}>here</Link> before saving.
      </p>
    </div>;
  }

  return <div className="flex justify-end content-end">
    <div className="w-full bg-off-white px-3 py-6 my-5 block">
      <p className={"mb-0"}>
        <strong>Registered Number</strong>: {agencyFinancialsUsed?.registered_number || "Missing"}{", "}
        <strong>VAT Number</strong>: {agencyFinancialsUsed?.vat_number || "Missing"}{", "}
        <strong>Registered Office</strong>: {agencyFinancialsUsed?.registered_office || "Missing"}

      </p>
      <p className={"mb-0"}>
        <strong>Bank Name</strong>: {agencyFinancialsUsed?.bank_name || "Missing"},{" "}
        <strong>Account Name</strong>: {agencyFinancialsUsed?.account_name || "Missing"}, {" "}
        <strong>Address</strong>: {agencyFinancialsUsed?.bank_address}
      </p>
      <p className={"mb-0"}>
        <strong>Account Number</strong>: {agencyFinancialsUsed?.account_number || "Missing"}{", "}
        <strong>Sort Code</strong>: {agencyFinancialsUsed?.sort_code || "Missing"}{", "}
        <strong>IBAN</strong>: {agencyFinancialsUsed?.iban || "Missing"}{", "}
        <strong>BIC</strong>: {agencyFinancialsUsed?.bic || "Missing"}{", "}
        <strong>TIDS no</strong>: {agencyFinancialsUsed?.tids_no || "Missing"}{", "}
      </p>

    </div>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    ...state.agencyFinancials,
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchAgencyFinancialsFromEvent,
  unloadAgencyFinancials,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialsComponent);
