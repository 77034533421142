import React, { useEffect } from "react";
import { connect } from "react-redux";
import { array, bool, object, shape, string } from "prop-types";
import { venueInteraction } from "modules/analytics";
import Gallery from "./Gallery";
import { isAgency, isCorporate, isInternal, isLoggedIn } from "../../modules/auth";
// import { FaVideo } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import ReactTooltip from "react-tooltip";
import { useHistory, useLocation } from "react-router-dom";
import BackgroundImage from "../../Components/General/BackgroundImage";
import Video from "./Video";
import { useSelector } from "react-redux";
import { VENUE_TAGS } from "../../modules/analytics/tags";

const urlFromImage = image => image && (image.laravel_upload ? `${image.path}` : `${window.IMG_URL}/${image.path}`);

const getFirstImageUrl = images => Array.isArray(images) && urlFromImage(images[0]);

const getBannerImageUrl = (images, featuredImage) =>
  (urlFromImage(featuredImage) ||
    getFirstImageUrl(images) ||
    "/img/venue-placeholder.jpg").replace("stage-api", "api").replace("localapi", "api");

Banner.propTypes = {
  featuredImg: object,
  images: array,
  name: string.isRequired,
  hasMeetingRooms: bool.isRequired,
  hasHealthAndSafety: bool.isRequired,
  hasContacts: bool.isRequired,
  hasSiteVisitReports: bool.isRequired,
  anchors: shape({
    details: string.isRequired,
    contacts: string.isRequired,
    meetingRooms: string.isRequired,
    location: string.isRequired,
    facilitiesAndTechnical: string.isRequired,
    destination: string.isRequired,
    siteVisits: string.isRequired,
    healthAndSafety: string.isRequired,
  }).isRequired,
};

function Banner({
                  featuredImg,
                  name,
                  anchors,
                  hasDestination,
                  hasHealthAndSafety,
                  hasContacts,
                  covid,
                  hasSiteVisitReports,
                  images,
                  venueInteraction,
                  venueId,
                  impression_id,
                  data_score,
                  isLicensed,
                  isInternal,
                  slug,
                  virtualTours,
                  facilitiesAndTechnical,
                  videoLink,
                  hotelBrand,
                  hotelGroup,
                  hasOffers,
                  hasArticles,
                  hasMeetingRooms,
                  venuePermission,
                  isLoggedIn,
                  handleFacilitiesAndTechnicalTab
                }) {
  const history = useHistory();
  const location = useLocation();
  const track = type =>
    venueInteraction({
      type,
      id: venueId,
      impression_id,
      data_score,
    });


  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const bannerImageUrl = getBannerImageUrl(images, featuredImg);
  const isUserAgency = useSelector(state => isAgency(state));
  const isUserCorporate = useSelector(state => isCorporate(state));

  let freeTier = (venuePermission === "free" && !isUserAgency && !isUserCorporate && !isInternal);
  const bannerImageContent = (covid) => {
    return (
      <>
        {/*{covid ? <span className={'ribbon-single'} title="Open during Covid-19 restrictions">Open</span> : null}*/}
        <div
          className="absolute pin-b pin-l w-full"
          style={{
            height: 100,
            backgroundImage:
              "linear-gradient(to top, rgba(0,0,0,.3), transparent)",
          }}
        />
        <h1
          className="px-3 text-4xl xl:text-5xl text-white text-shadow flex flex-col justify-end pb-3 relative lg:pr-250"
          style={{ height: "24rem" }}
        >
          {(hotelBrand || hotelGroup) && (
            <div className="flex">
              {hotelBrand && hotelBrand.cdn_path && (
                <div className="bg-white px-4">
                  <img
                    src={hotelBrand.cdn_path}
                    style={{
                      maxWidth: 96,
                    }}
                  />
                </div>
              )}
              {(!hotelBrand || !hotelBrand.cdn_path) && hotelGroup && hotelGroup.image_file && (
                <img
                  src={hotelGroup.cdn_path}
                  style={{
                    maxWidth: 96,
                  }}
                />
              )}
            </div>
          )}
          {isInternal ? (
            <a
              className="text-white"
              href={`/admin/edit-venue/${slug}/overview`}
            >
              {name}
            </a>
          ) : (
            name
          )}
        </h1>
        {isInternal ? (
          <div
            className="absolute pin-t pin-r bg-black rounded-bl-lg"
            style={{ backgroundColor: "rgba(0,0,0,0.76)" }}
          >
            <p className="mx-6 my-3 text-white">
              {isLicensed ? "Licensed" : "Unlicensed"}
            </p>
          </div>
        ) : null}
        {/*{!isInternal ? (*/}
        {/*  <div*/}
        {/*    className="absolute pin-t pin-r py-1 px-2 bg-edge-blue uppercase text-white text-sm font-normal"*/}
        {/*    style={{ backgroundColor: "rgba(0, 153, 255)" }}*/}
        {/*  >*/}
        {/*       <span*/}
        {/*         data-tip*/}
        {/*         data-for="clickme"*/}
        {/*         data-event="click"*/}
        {/*         className="flex items-center pointer"*/}
        {/*       >*/}
        {/*    Covid - Our Commitment*/}
        {/*  </span>*/}
        {/*    <ReactTooltip*/}
        {/*      id="clickme"*/}
        {/*      effect="solid"*/}
        {/*      type="light"*/}
        {/*      clickable={true}*/}
        {/*      className="tooltip-shadow op-1"*/}
        {/*      globalEventOff="click"*/}
        {/*    >*/}
        {/*      <div className="flex text-transform-none">*/}
        {/*        <p>Whilst the capacities currently shown on the platform are pre-COVID, our commitment to you is to work*/}
        {/*          together with the venues to find out the capacities that are relevant to the current social distancing*/}
        {/*          measures.</p>*/}
        {/*      </div>*/}
        {/*    </ReactTooltip>*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </>
    );
  };

  return (
    <React.Fragment>
      {bannerImageUrl.includes("api") ?
        <div
          className="container bg-cover bg-center relative overflow-hidden"
          style={{
            backgroundImage: `url(${bannerImageUrl}?raw=1)`,
          }}
        >
          {bannerImageContent(covid)}
           <div className="flex absolute pin-r pr-6" style={{ bottom: 30 }}>
              {/* <div
                className="rounded-50 bg-grey flex flex-col z-20 items-center justify-center mr-4"
                style={{ width: 64, height: 64 }}
              >
                <FaVideo className="text-white text-2xl" />
                <span className="font-heading font-bold uppercase text-white text-xs">
                  Video
                </span>
              </div> */}
              {!isLoggedIn && virtualTours.length && isLicensed ? (
                <a
                  onClick={() => {
                    history.push(
                      `/login?return=${encodeURIComponent(location.pathname)}&logInToProceed=1`,
                    )
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center mr-4 hover:bg-black transition"
                  style={{ width: 64, height: 64 }}
                >
                  <div className="flex" style={{ height: 24 }}>
                    <img src="/img/tour-big-icon.svg" alt="Tour" />
                  </div>
                  <span className="font-heading font-bold uppercase text-white text-xs">
                    Tour
                  </span>
                </a>
              ) : virtualTours.length && isLicensed ? (
                <a
                  href={virtualTours}
                  onClick={() => {
                    track(VENUE_TAGS.VIEW_VIRTUAL_TOUR);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center mr-4 hover:bg-black transition"
                  style={{ width: 64, height: 64 }}
                >
                  <div className="flex" style={{ height: 24 }}>
                    <img src="/img/tour-big-icon.svg" alt="Tour" />
                  </div>
                  <span className="font-heading font-bold uppercase text-white text-xs">
                    Tour
                  </span>
                </a>
              ) : null}

              {images.length ? (
                <Gallery
                  classes="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center hover:bg-black transition"
                  venueId={venueId}
                  impression_id={impression_id}
                  data_score={data_score}
                  images={freeTier ?
                    images.slice(0, 4).map(img => img.path ?
                      { path: img.path, caption: img.caption } :
                      { path: `${window.IMG_URL}/${img.path}`, caption: img.caption },
                    ) :
                    images.map(img => img.path ?
                      { path: img.path, caption: img.caption } :
                      { path: `${window.IMG_URL}/${img.path}}`, caption: img.caption },
                    )}
                />
              ) : null}
              {videoLink ? (
                <Video
                  classes="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center hover:bg-black transition ml-4"
                  venueId={venueId}
                  impression_id={impression_id}
                  data_score={data_score}
                  videoLink={videoLink}
                />
              ) : null}
            </div>
        </div>
        :
        <BackgroundImage
          className="container bg-cover bg-center relative overflow-hidden"
          imagePath={bannerImageUrl}
          edits={{
            "resize": {
              "width": 1452,
              "height": 372,
              "fit": "cover",
            },
          }}
        >
          {bannerImageContent(covid)}
           <div className="flex absolute pin-r pr-6" style={{ bottom: 30 }}>
              {/* <div
                className="rounded-50 bg-grey flex flex-col z-20 items-center justify-center mr-4"
                style={{ width: 64, height: 64 }}
              >
                <FaVideo className="text-white text-2xl" />
                <span className="font-heading font-bold uppercase text-white text-xs">
                  Video
                </span>
              </div> */}
              {!isLoggedIn && virtualTours.length && isLicensed ? (
                <a
                  onClick={() => {
                    history.push(
                      `/login?return=${encodeURIComponent(location.pathname)}&logInToProceed=1`,
                    )
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center mr-4 hover:bg-black transition"
                  style={{ width: 64, height: 64 }}
                >
                  <div className="flex" style={{ height: 24 }}>
                    <img src="/img/tour-big-icon.svg" alt="Tour" />
                  </div>
                  <span className="font-heading font-bold uppercase text-white text-xs">
                    Tour
                  </span>
                </a>
              ) : virtualTours.length && isLicensed ? (
                <a
                  href={virtualTours}
                  onClick={() => {
                    track(VENUE_TAGS.VIEW_VIRTUAL_TOUR);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center mr-4 hover:bg-black transition"
                  style={{ width: 64, height: 64 }}
                >
                  <div className="flex" style={{ height: 24 }}>
                    <img src="/img/tour-big-icon.svg" alt="Tour" />
                  </div>
                  <span className="font-heading font-bold uppercase text-white text-xs">
                    Tour
                  </span>
                </a>
              ) : null}

              {images.length ? (
                <Gallery
                  classes="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center hover:bg-black transition"
                  venueId={venueId}
                  impression_id={impression_id}
                  data_score={data_score}
                  images={freeTier ?
                    images.slice(0, 4).map(img => img.path ?
                      { path: img.path, caption: img.caption } :
                      { path: `${window.IMG_URL}/${img.path}`, caption: img.caption },
                    ) :
                    images.map(img => img.path ?
                      { path: img.path, caption: img.caption } :
                      { path: `${window.IMG_URL}/${img.path}}`, caption: img.caption },
                    )}
                />
              ) : null}
              {videoLink ? (
                <Video
                  classes="rounded-50 bg-edge-blue pointer flex flex-col z-10 items-center justify-center hover:bg-black transition ml-4"
                  venueId={venueId}
                  impression_id={impression_id}
                  data_score={data_score}
                  videoLink={videoLink}
                />
              ) : null}
            </div>
        </BackgroundImage>
      }

      <div className="container bg-grey">
        <div className="row">
          <div className="bg-white px-5 w-full relative mb-6 flex flex-wrap">
            <ScrollLink
              smooth
              offset={-110}
              to={anchors.details}
              className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
              onClick={() => {
                track(VENUE_TAGS.VIEW_DETAILS);
              }}
            >
              Venue details
            </ScrollLink>
            {hasMeetingRooms && (
              <ScrollLink
                smooth
                offset={-110}
                to={anchors.meetingRooms}
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => {
                  track(VENUE_TAGS.VIEW_MEETING_ROOMS.VIEW_MEETING_ROOMS);
                }}
              >
                Meeting rooms
              </ScrollLink>
            )}
            <ScrollLink
              smooth
              offset={-110}
              to={anchors.features}
              className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
              onClick={() => {
                track(VENUE_TAGS.VIEW_FEATURES);
              }}
            >
              Features
            </ScrollLink>
            {facilitiesAndTechnical && (
              <ScrollLink
                smooth
                offset={-110}
                to={anchors.facilitiesAndTechnical}
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => {
                  track(VENUE_TAGS.VIEW_FACILITIES);
                }}
              >
                Facilities and technical
              </ScrollLink>
            )}
            {facilitiesAndTechnical && (
              <ScrollLink
                smooth
                offset={-110}
                to={anchors.features}
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => handleFacilitiesAndTechnicalTab(anchors.esg)}
              >
                ESG
              </ScrollLink>
            )}
            {hasSiteVisitReports && isInternal ? (
              <ScrollLink
                smooth
                offset={-110}
                to={anchors.siteVisits}
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => {
                  track(VENUE_TAGS.VIEW_SITE_VISITS);
                }}
              >
                Site Visit Insights
              </ScrollLink>
            ) : null}
            {hasHealthAndSafety ? (
              <ScrollLink
                smooth
                offset={-110}
                to="health-and-safety"
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => {
                  track(VENUE_TAGS.VIEW_HEALTH_AND_SAFETY);
                }}
              >
                Health & Safety
              </ScrollLink>
            ) : null}
            {hasOffers && (
              <ScrollLink
                smooth
                offset={-110}
                to={anchors.offers}
                className="pointer text-dark-red font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
              >
                Special offers
              </ScrollLink>
            )}
            {hasArticles && (
              <ScrollLink
                to={anchors.news}
                smooth
                offset={-110}
                className="pointer text-black font-heading font-bold uppercase px-5 py-5 hover:text-green transition"
                onClick={() => {
                  track(VENUE_TAGS.VIEW_FACILITIES);
                }}
              >
                Latest news
              </ScrollLink>
            )}

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  state => ({
    isInternal: isInternal(state),
    isLoggedIn: isLoggedIn(state)
  }),
  { venueInteraction },
)(Banner);
