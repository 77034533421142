import React, {Suspense, lazy, useState} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Public from "./Public";
import Corporate from "./Corporate";
import ScrollToTop from "Components/ScrollToTop";
import {LoadingHalfPage} from "../Components/Loading";
import SentryAppendDetails from "../Components/SentryAppendDetails";
import {RouteTracker} from "../modules/google-tag-manager";
import Seo from "Components/Seo";
import {useInterval} from "../hooks/useInterval";
import {getVersions} from "../services/versionService";

const Admin = lazy(() => import("./Admin" /* webpackChunkName: "admin" */));


const VersionCheck = () => {
    const shouldCheck = window.EDGE_VERSION !== 'dev';

    const [versions, setVersions] = useState({portal: false});
    useInterval(() => {
        if (!shouldCheck) {
            return; // do nothing in dev mode.
        }
        getVersions().then(({result, error}) => {
            if (result) {
                setVersions(result);
            } else {
                console.error('Error fetching versions:', error);
            }
        });
    }, 60000); // 1min
    // console.log({versions});
    if (!shouldCheck) {
        return null;
    }

    //ignore -stage/-demo tag parts
    let checkVersion = window.EDGE_VERSION.split('-')[0];
    let portalVersion = versions?.portal && versions.portal !== 'unknown' ? versions.portal.split('-')[0] : false;
    // console.log({versions, checkVersion, 'w': window.EDGE_VERSION, portalVersion});
    if (portalVersion && portalVersion !== checkVersion) {
        const updateHref = new URL(document.location.toString());
        updateHref.searchParams.set('v', versions.portal);
        return <div className={'text-center bg-brand font-bold p-4'}><a className={'text-white underline'} href={updateHref.toString()}>A new version is available - click here to update</a></div>
    }
    return null;
};

export default function Router() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Seo/>
                <RouteTracker/>
                <SentryAppendDetails/>
                {window?.EDGE_VERSION === 'dev' ?
                    <div className={'text-center bg-red p-0 text-white text-xs'}>DEV SITE</div>
                    : (window?.EDGE_VERSION?.split('-')[1] === 'stage' ?
                        <div className={'text-center bg-orange p-0 text-white text-xs'}>STAGE SITE</div>
                        : null)
                }
                <VersionCheck/>
                <Suspense fallback={<LoadingHalfPage/>}>
                    <Switch>
                        <Route path="/admin" render={props => <Admin {...props} />}/>
                        <Route path="/corporate" render={props => <Corporate {...props}  />}/>
                        <Route component={Public}/>
                    </Switch>
                </Suspense>
            </ScrollToTop>
        </BrowserRouter>
    );
}
