import React, { useState } from "react";
import moment from "moment";
import { ButtonSpan } from "Components/Button";
import { LabelledText, WhitePanelWithHeader } from "ui";
import { hasCorporateAccount, isRoleEdgeAdmin } from "../../modules/permissions";
import { connect } from "react-redux";
import { isSuperAgent } from "../../modules/auth";

const Col = ({ title, text, className = "md:w-1/2 lg:w-1/4 pr-2" }) => (
  <div className={className}>
    <LabelledText label={title} text={text} />
  </div>
);

const BoolWithNotes = ({ title, bool, notes }) =>
  bool ? (
    <div className="flex">
      <Col title={title} text={bool ? "Yes" : "No"} />
      {notes && (
        <div className="md:w-1/2 lg:w-3/4">
          <LabelledText label={`${title} Notes`} text={notes} />
        </div>
      )}
    </div>
  ) : null;

const mapStateToProps = (state) => {
  return {
    hasCorporateAccount: hasCorporateAccount(state.auth),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isSuperAgent: isSuperAgent(state),
  };
};

const BriefSummary = ({
                        expanded,
                        number_of_delegates,
                        maximum_number_of_delegates,
                        date_from,
                        date_to,
                        date_flexible,
                        bedrooms_required,
                        bedroom_dates,
                        bedroom_additional_notes,
                        room_style,
                        back_projection,
                        syndicate_room,
                        syndicate_room_notes,
                        exhibition_space,
                        exhibition_space_notes,
                        private_dining,
                        private_dining_notes,
                        any_equipment,
                        any_equipment_notes,
                        date_flexible_notes,
                        additional_notes,
                        event_timings,
                        briefPreferences,
                        isVenue,
                        date_decision_estimate,
                        custom_field_questions,
                        custom_field_values,
                        hasCorporateAccount,
                        isRoleEdgeAdmin,
                        enquiryVenueData,
                        syndicateRooms,
                        decision_making_process,
                        probing_questions,
                        isSuperAgent,
                      }) => {
  const [showAll, setShowAll] = useState(expanded);
  if (room_style === 'Other') {
    room_style = 'Other - please see notes';
  }

  const hasNotes =
    date_flexible ||
    syndicate_room ||
    private_dining ||
    exhibition_space ||
    any_equipment ||
    additional_notes;
  return (
    <WhitePanelWithHeader icon="far fa-clipboard" title="Event Brief">
      <div className="md:flex flex-wrap">
        <Col title="Delegates" text={`${number_of_delegates} People`}/>
        {maximum_number_of_delegates ? (
          <Col title="Max. Delegates" text={`${maximum_number_of_delegates} People`}/>
        ) : null}
        <Col
          title="Event Start Date"
          text={moment(date_from).format("ddd Do MMM YYYY")}
        />
        <Col
          title="Event End Date"
          text={moment(date_to).format("ddd Do MMM YYYY")}
        />
        {date_decision_estimate && (
          <Col
            title="Date Decision Estimate"
            text={moment(date_decision_estimate).format("ddd Do MMM YYYY")}
          />
        )}
        <Col title="Dates Flexible" text={date_flexible ? "Yes" : "No"} />
        <Col
          title="Bedrooms required"
          text={bedrooms_required ? "Yes" : "No"}
        />
        <Col
          title="No of Nights"
          text={
            bedroom_dates && bedroom_dates.length ? bedroom_dates.length : "N/A"
          }
        />
        <Col title="Venue Style" text={room_style || "N/A"} />
        <Col title="Back Projection" text={back_projection ? "Yes" : "No"} />
        {event_timings && (
          <Col title="Event Timings" text={event_timings} />
        )}
        {!isVenue && (
          <>
            {briefPreferences && briefPreferences.location && <Col title="Location" text={briefPreferences.location} />}
            {briefPreferences && briefPreferences.event_type &&
              <Col title="Event Type" text={briefPreferences.event_type} />}
            {briefPreferences && briefPreferences.budget && <Col title="Budget" text={briefPreferences.budget} />}
          </>
        )}
      </div>
      {enquiryVenueData && enquiryVenueData.pharmaceutical_questions && (
        <div>
          <hr />
          <h4>Pharmaceutical Questions</h4>
          <br />
          <div className="md:flex flex-wrap">
            <Col title="Will there be any building work taking place at the time of the event "
                 text={`${enquiryVenueData.pharmaceutical_questions.building_work ? "Yes" : "No"}`} />
            <Col title="Are there any other Healthcare Suppliers/Pharma Companies in house at the time of the event"
                 text={`${enquiryVenueData.pharmaceutical_questions.other_pharma ? "Yes" : "No"}`} />
            <Col title="Please confirm Event Space Access Times"
                 text={`${enquiryVenueData.pharmaceutical_questions.event_space_access_times ? enquiryVenueData.pharmaceutical_questions.event_space_access_times : ""}`} />
            <Col title="Please confirm your acceptance of the payment terms requested by the client"
                 text={`${enquiryVenueData.pharmaceutical_questions.accept_payment_terms ? "Yes" : "No"}`} />
            {!enquiryVenueData.pharmaceutical_questions.accept_payment_terms ? <Col title="Venue Agreed Payment Terms"
                                                                                    text={`${enquiryVenueData.pharmaceutical_questions.payment_terms_text ? enquiryVenueData.pharmaceutical_questions.payment_terms_text : ""}`} /> : null}
          </div>
        </div>

      )}
      {isSuperAgent && (decision_making_process || probing_questions) ? <div className="flex flex-wrap">
        {decision_making_process ? <Col
          className={"col-w-1/2 pr-2"}
          title="Decision making process"
          text={decision_making_process}
        /> : null}
        {probing_questions ? <Col
          className={"col-w-1/2 pr-2"}
          title="Probing Questions"
          text={probing_questions}
        /> : null}
      </div> : null}
      {custom_field_questions && custom_field_values && (hasCorporateAccount || isRoleEdgeAdmin) && !isVenue && (
        <div>
          <hr />
          <h4>Additional Fields</h4>
          <br />
          <div className="md:flex flex-wrap">
            {custom_field_values.map(value => {
              if (value.show_on_output) {
                if (value.custom_field_value) {
                  let outputValue = value.custom_field_value;
                  if (typeof outputValue === "string") {
                    outputValue = outputValue.replace(/["']/g, "");
                  }

                  return (<Col key={value.field_identifier} title={value.question_label}
                               text={`${outputValue ? outputValue : ""} `} />);
                }
              }
            })}
          </div>
        </div>
      )}
      {!showAll && (bedroom_dates && bedroom_dates.length > 0 || hasNotes) && (
        <div className="text-center uppercase text-sm">
          <ButtonSpan onClick={() => setShowAll(true)}>
            <i className="fal fa-chevron-down"></i> See the full brief
          </ButtonSpan>
        </div>
      )}
      {showAll && (
        <div>
          {bedroom_dates && bedroom_dates.length > 0 && (
            <>
              <h4 className="mt-5 mb-2">Accommodation Requirements</h4>
              <div className="flex flex-wrap">
                {bedroom_dates.sort((a, b) => moment(a.date) - moment(b.date)).map(val => (
                  <Col
                    key={moment(val.date).format("ddd Do MMM YYYY")}
                    title={moment(val.date).format("ddd Do MMM YYYY")}
                    text={`${val.quantity} bedrooms`}
                  />
                ))}
              </div>
            </>
          )}
          <div className="flex flex-wrap">
            {bedroom_additional_notes && (
              <Col
                title="Bedroom Notes"
                text={bedroom_additional_notes}
              />
            )}
          </div>

          {hasNotes && (
            <>
              <h4 className="mt-5 mb-2">Notes</h4>
              <BoolWithNotes
                title="Flexible Dates"
                bool={date_flexible}
                notes={date_flexible_notes}
              />
              <BoolWithNotes
                title="Syndicate Rooms"
                bool={syndicate_room}
                notes={syndicate_room_notes}
              />
              {syndicateRooms && syndicateRooms.map(room => {
                  return <div className="flex flex-wrap" key={room.id}>
                    {room.room_style ? <Col title="Style" text={room.room_style.name} /> : null}
                    <Col title="Number of Delegates" text={room.number_of_delegates} />
                    <Col title="Quantity of Syndicate Rooms" text={room.quantity} />
                  </div>;
                },
              )}
              <BoolWithNotes
                title="Exhibition Space"
                bool={exhibition_space}
                notes={exhibition_space_notes}
              />
              <BoolWithNotes
                title="Catering"
                bool={private_dining}
                notes={private_dining_notes}
              />
              <BoolWithNotes
                title="Any Additional Equipment"
                bool={any_equipment}
                notes={any_equipment_notes}
              />
              <LabelledText label="Additional Notes" text={additional_notes} />
            </>
          )}
          {showAll && (bedroom_dates && bedroom_dates.length > 0 || hasNotes) && (
            <div className="text-center uppercase text-sm">
              <ButtonSpan onClick={() => setShowAll(false)}>
                <i className="fal fa-chevron-up"></i> Show less
              </ButtonSpan>
            </div>
          )}
        </div>
      )}
    </WhitePanelWithHeader>
  );
};

export default (connect(
  mapStateToProps,
  null,
)(BriefSummary));
