import React, { useEffect, useState } from "react";
import { fetchInvoicePdfs, unloadInvoicePdfs } from "../../redux/actions/invoicePdfs";
import { token } from "../../modules/auth";
import { connect } from "react-redux";
import axios from "axios";
import { saveData } from "../../services/downloadFile";
import Loading from "../Loading";
import { nameFormatter } from "../../utils";
import { formatDate } from "../../modules/dates";

const InvoicePdfs = ({ event, fetchInvoicePdfs, token, invoicePdfs, isLoadingInvoicePdfs, unloadInvoicePdfs }) => {
  const [downloading, setDownloading] = useState(null);

  useEffect(() => {
    if (event) {
      fetchInvoicePdfs(event.id, token);
    }
    return () => unloadInvoicePdfs();
  }, []);

  const handleDownload = async (id, fileName, type = "application/pdf") => {
    setDownloading(id);
    try {
      const response = await axios.get(`${window.API}/event/invoice/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      saveData(type, response.data, fileName);
      setDownloading(null);
    } catch (err) {
      alert("Sorry there was a problem fetching the file");
    }
  };

  if (invoicePdfs.length === 0) {
    return null;
  }

  if (isLoadingInvoicePdfs) {
    return <Loading />;
  }

  return <div>
    <ul>
      {invoicePdfs?.sort((a, b) => b.id - a.id).map((invoicePdf, key) => {
        let latest = key === 0;
        return <li
          className={"my-4"}
          key={invoicePdf.id}
          onClick={() => handleDownload(invoicePdf.id, invoicePdf?.invoice_pdf_name)}
        >
          <span className={"cursor-pointer"}>
            {downloading === invoicePdf.id ? <>
              Downloading...
            </> : (
              <>Download {latest ? "Latest" : null} Revision (Created
                By: {nameFormatter(invoicePdf.created_by)} on {formatDate(invoicePdf.created_at, "D/M/YY hh:mm a")})
              </>
            )}
          </span>
        </li>;
      })}
    </ul>
  </div>;
};

const mapStateToProps = state => {
  return {
    ...state,
    ...state.invoicePdfs,
    token: token(state),
  };
};

export const mapDispatchToProps = {
  fetchInvoicePdfs,
  unloadInvoicePdfs,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePdfs);
