export const FRONTEND = {
  DASHBOARD: {
    VIEW: "/corporate/dashboard"
  },
  COMPANY: {
    ALL: '/admin/agency/company',
    EDIT: '/admin/agency/company/edit/:id?',
    EDIT_OWN: '/admin/company/edit',
    CREATE: '/admin/agency/company/create'
  },
  AGENCY: {
    ALL: '/admin/agency',
    MANAGE: '/admin/agency/manage',
    EDIT: '/admin/agency/:id/:section',
    CREATE: '/admin/agency/create',
    USER_EMAIL: '/admin/agency/edit/:email?',
    FINANCIALS: {
      EDIT: '/admin/agency/:agencyId/financials'
    },
    REPORTS: {
      VIEW: '/admin/agency/reports',
      CASH_FLOW: '/admin/agency/reports/cash-flow',
      ENQUIRY_LOG: '/admin/agency/reports/enquiry',
      SNAPSHOT: '/admin/agency/reports/snapshot',
      SPEND_REPORT: '/admin/agency/reports/spend-report',
    }
  },
  USER_INVITES: {
    AGENCY_LANDING_PAGE: '/invite-agency-user/:invite_code',
    COMPANY_LANDING_PAGE: '/invite-company-user/:invite_code'
  },
  CORPORATE_USER: {
    CMS: {
      EVENT_ORGANISERS_GUIDE: '/corporate/event-organisers-guide',
      CORPORATE_CONTACT_US: '/corporate/contact-us',
    },
    MY_EDGE: {
      VIEW: '/corporate/my-edge'
    },
    SITE_VISITS: {
      VIEW: '/corporate/site-visits',
      ALL: '/corporate/site-visits/all',
      SINGLE: '/corporate/site-visits/:siteVisitId',
      MY_REPORTS: '/corporate/site-visits/my-reports',
      CREATE: '/corporate/site-visits/create',
    },
    SETTINGS: {
      VIEW: '/corporate/settings'
    },
    DOCUMENTS: {
      VIEW: '/corporate/documents',
      SINGLE_CATEGORY: '/corporate/documents/category/:category',
    },
    LOCATIONS: {
      VIEW: '/corporate/locations',
    },
    REPORTS: {
      VIEW: '/corporate/reports',
      ENQUIRY_LOG: '/corporate/reports/enquiry',
      SNAPSHOT: '/corporate/reports/snapshot',
      SPEND_REPORT: '/corporate/reports/spend-report',
      CASH_FLOW_REPORT: '/corporate/reports/cash-flow',
      MONTHLY_EVENT_VALUES_REPORT: '/corporate/reports/monthly-events-report',
    },
    CALENDAR: {
      VIEW: '/corporate/calendar',
    },
    NEWS: {
      VENUE_NEWS: '/corporate/news/venue-news',
      INSIGHTS: '/corporate/news/insights',
      SPECIAL_OFFERS: '/corporate/news/special-offers',
      CORPORATE_TEAM: '/corporate/news/corporate-team-updates'
    },
    INDUSTRY_EVENTS: {
      MANAGE: '/corporate/manage-industry-events'
    },
    EVENTS: {
      VIEW: '/corporate/events',
    },
    BOOKED_EVENTS: {
      ALL: '/corporate/events/all',
      SINGLE: '/corporate/events/:slug'
    },
    TEMPLATES: {
      VIEW: '/corporate/templates'
    },
    COMPANY: {
      EDIT: '/corporate/company/edit-profile',
    },
    PROPOSALS: {
      SHARE: '/corporate/proposal/view/:shareId',
      AUTHORISE: '/corporate/proposal/authorise/:authoriseId'
    },
  },
  CORPORATE: {
    ALL: '/admin/corporate-accounts/manage',
    CREATE: '/admin/corporate-accounts/create',
    EDIT: '/admin/corporate-accounts/:slug/:section',
    DOCUMENTS: {
      CATEGORY: '/admin/corporate-accounts/:slug/view-documents',
      SINGLE_CATEGORY: '/admin/corporate-accounts/:slug/documents/category/:category',
      ADMIN_SINGLE_CATEGORY: '/admin/corporate-accounts/:slug/:section/category/:category',
      CREATE: '/admin/corporate-accounts/:slug/:section/create',
      EDIT: '/admin/corporate-accounts/:slug/:section/:id/edit',
      MANAGE: '/admin/corporate-accounts/:slug/manage-documents',
      VIEW: '/admin/corporate-accounts/:slug/view-documents',
    },
    LOCATIONS: {
      SINGLE: '/admin/corporate-accounts/:slug/location/:id',
      CREATE: '/admin/corporate-accounts/:slug/location/create',
      EDIT: '/admin/corporate-accounts/:slug/location/:id/edit',
      MANAGE: '/admin/corporate-accounts/:slug/manage-locations',
      VIEW:  '/admin/corporate-accounts/:slug/company-locations',
    },
    INDUSTRY_EVENTS: {
      MANAGE: '/admin/corporate-accounts/:slug/manage-industry-events'
    },
  },

  EVENTS: {
    ALL: '/admin/corporate-accounts/calendar-events',
    COMPANY_ALL: '/admin/corporate-accounts/:slug/events-calendar'
  },
  LOCATIONS: {
    COMPANY_ALL: '/admin/corporate-accounts/:slug/company-locations'
  },
  BOOKED_EVENTS: {
    ALL: '/admin/events/',
    SINGLE: '/admin/events/:slug'
  },
  PROPOSALS: {
    REQUEST: '/corporate/request-for-proposal',
    SHARE: '/proposal/view/:shareId',
    AUTHORISE: '/proposal/authorise/:authoriseId'
  }
}

export const API = {
  COMPANY: {
    LIST: '/company/',
    LIST_EVENTS: '/company/events',
    SINGLE: '/company/:id',
    INVITE_USER: '/company/:id/invite',
    USER_INVITES: '/company/:id/invites',
    USER: {
      LIST: '/company/:id/users',
      EXPORT_TOKENS: '/company/:id/user-login-tokens/export',
      DISABLE_BY_TOKEN: '/user/disable-by-token'
    },
    BRANCHES: {
      LIST: '/company/:companyId/branches',
      SINGLE: '/company/:companyId/branch/:id',
      CREATE: '/company/:companyId/branch'
    }
  },
  USER_INVITES: {
    SINGLE: '/invite/:invite_code',
    RESEND: '/invite/resend/:invite_code',
    REGISTER: '/invite/:register/:invite_code'
  },
  COMPANIES: {
    SEARCH: '/companies',
    SEARCH_EDGE: '/companies?code=edge',
    SEARCH_TRIN: '/companies?code=trin',
  },
  AGENCY: {
    LIST: '/agency/',
    LIST_FOR_SELECT: '/agency/agencies-for-select',
    SINGLE: '/agency/:id',
    INVITE_USER: '/agency/:id/invite',
    USER_INVITES: '/agency/:id/invites',
    SINGLE_BY_SLUG: '/agency-by-slug/:slug/',
    SUPER_AGENCY: '/agency/selected-agencies',
    BUSINESS_UNITS: {
      LIST: '/agency/:id/business-units',
      SINGLE: '/agency/:id/business-unit/:businessUnit',
      CREATE: '/agency/:id/business-unit'
    },
    NOMINAL_CODE: {
        LIST: '/agency/:agencyId/nominal-code/',
        SINGLE: '/agency/:agencyId/nominal-code/:id',
    },
    FINANCIALS: {
      FETCH: '/agency/:agencyId/financials',
      FETCH_ON_EVENT: '/event/:eventId/agency-financials',
      VIEW: '/agency/:agencyId/financials/:id',
    },
    BOOKING_DESKS: {
      LIST: '/agency/:slug/booking-desks',
      LIST_CORPORATE: '/agency/:slug/corporate-account/booking-desks',
      SINGLE: '/agency/:slug/booking-desk/:id',
      CREATE: '/agency/:slug/booking-desk',
      EDIT: '/agency/:slug/booking-desk/:id',
      DELETE: '/agency/:slug/booking-desk/:id'
    },
    REPORTS: {
      CASH_FLOW_REPORT: '/report/cash-flow-report',
      CASH_FLOW_REPORT_EXPORT: '/report/cash-flow-report/export'
    }
  },
  USER: {
    LIST: '/users',
    SINGLE: '/users/:email',
  },
  NOTES: {
    LIST: '/rfp/enquiry/:rfpId/notes',
    CREATE: '/rfp/enquiry/:rfpId/notes/add',
    EDIT: '/rfp/enquiry/:rfpId/notes/:noteId',
  },
  BOOKED_EVENTS: {
    ALL: '/event',
    CALENDAR: '/events/calendar',
    SINGLE: '/event/:slug',
    CONFIRMATION: '/event/:slug/confirm',
    DELETE_FILE: '/documents/event/:slug/upload/:id',
    FINAL_COMMISSION_VALUE: '/event/:slug/final-commission-values/',
    EXPORT: '/event/:slug/export/:type',
    EXPORT_ALL: '/event/export/:type',
    INVOICE: '/event/:slug/invoice',
    COMPLETE: '/event/:slug/complete',
    CHASE: '/event/:slug/:chase',
    INVOICES: {
      LATEST: '/event/:event/invoice-pdf',
      SINGLE: '/event/invoice/:invoice',
      ALL: '/event/:event/invoice-pdfs'
    }
  },
  CORPORATE: {
    LIST: '/corporate-accounts/',
    SINGLE: '/corporate-accounts/:slug',
    CREATE: '/corporate-accounts/create',
    EDIT: '/corporate-accounts/:slug/edit',
    LIST_USERS: '/corporate-accounts/:slug/users',
    LOGO_IMAGE:  '/corporate-accounts/:slug/logo-image',
    PERMISSIONS: {
      LIST: '/corporate-accounts/permissions',
    },
    DOCUMENTS: {
      KPI_GET: '/corporate-accounts/:slug/kpi-file/:fileId',
      LIST: '/corporate-accounts/:slug/documents',
      SINGLE: '/corporate-accounts/:slug/document/:id',
      CREATE: '/corporate-accounts/:slug/document/',
      EDIT: '/corporate-accounts/:slug/document/:id',
      DELETE: '/corporate-accounts/:slug/document/:id',
      DELETEFILE: '/corporate-accounts/:slug/document/:id/file',
      CATEGORY: {
        ALL: '/corporate-accounts/:slug/document/categories',
        BY: '/corporate-accounts/:slug/document/category/:category/',
        CREATE: '/corporate-accounts/:slug/document/category',
        DELETE: '/corporate-accounts/:slug/document/category/:category/'
      }
    },
    BOOKING_DESKS: {
      LIST: '/corporate-accounts/:slug/booking-desks',
      LIST_CORPORATE: '/corporate-accounts/:slug/corporate-account/booking-desks',
      SINGLE: '/corporate-accounts/:slug/booking-desk/:id',
      CREATE: '/corporate-accounts/:slug/booking-desk',
      EDIT: '/corporate-accounts/:slug/booking-desk/:id',
      EDIT_CORPORATE: '/corporate-accounts/:slug/corporate-booking-desk/:id',
      DELETE: '/corporate-accounts/:slug/booking-desk/:id'
    },
    DEPARTMENTS: {
      LIST: '/corporate-accounts/:slug/departments',
      SINGLE: '/corporate-accounts/:slug/department/:id',
      CREATE: '/corporate-accounts/:slug/department',
      EDIT: '/corporate-accounts/:slug/department/:id',
    },
    LOCATIONS: {
      LIST: '/corporate-accounts/:slug/locations',
      SINGLE: '/corporate-accounts/:slug/location/:id',
      CREATE: '/corporate-accounts/:slug/location/',
      EDIT: '/corporate-accounts/:slug/location/:id'
    },
    REPORTS: {
      ENQUIRY_LOG: '/report/enquiry-log',
      ENQUIRY_LOG_EXPORT: '/report/enquiry-log/export',
      SNAPSHOT: '/report/snapshot/',
      SNAPSHOT_EXPORT: '/report/snapshot/export',
      SPEND_REPORT: '/report/spend-report',
      SPEND_REPORT_EXPORT: '/report/spend-report/export',
      MONTHLY_EVENT_VALUES_REPORT: '/report/:slug/monthly-events-report',
    }
  },
  EVENTS: {
    SINGLE: '/calendar-events/event/:id',
    PUBLIC: {
      LIST: '/calendar-events/public',
      CREATE: '/calendar-events/public',
      SINGLE: '/calendar-events/public/:id',
      EDIT: '/calendar-events/public/:id'
    },
    INDUSTRY: {
      LIST: '/calendar-events/industry',
      CREATE: '/calendar-events/industry',
      SINGLE: '/calendar-events/industry/:id',
      EDIT: '/calendar-events/industry/:id'
    },
    STATUS: {
      LIST: '/event-status',
      SINGLE: '/event-status/:id'
    }
  },
  ROLES: {
    LIST: '/roles',
    CORPORATE_LIST: '/roles/corporate-roles',
    UPDATE: '/roles/user/give',
    REVOKE: '/roles/user/revoke',
    GIVE_CORPORATE_ACCESS:  '/roles/user/give-corporate-access',
    REVOKE_CORPORATE_ACCESS: '/roles/user/revoke-corporate-access'
  },
  COUNTRIES: {
    LIST: '/countries',
  }
}
