import React from'react';
let EmailVerificationAlert = ({query}) => {

    if(!query || !query.message || (query.message !== 'successActivated' && query.message !== 'failedActivated')) return null;

    if(query.message === "successActivated") {
        return <div className='bg-brand px-3 py-6  block font-bold text-white  flex justify-center'>
            <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                Your account is verified! {query.type === 'agency' ? <span>Please <a  className={'text-white'} href="/login">login</a> to gain access to agent admin area </span> : null}
            </p>
        </div>
    }
    if(query.message === "failedActivated") {
        return <div className='bg-warning px-3 py-6 block font-bold text-white flex justify-center'>
            <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
               There is a problem verifying your account. Please get in touch with us.
            </p>

        </div>
    }

}

export default EmailVerificationAlert;