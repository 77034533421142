import {
  CASH_FLOW_REPORT_REQUEST, CASH_FLOW_REPORT_REQUEST_ERROR, CASH_FLOW_REPORT_REQUEST_RECEIVED, CASH_FLOW_REPORT_UNLOAD,
  ENQUIRY_LOG_REPORT_ERROR,
  ENQUIRY_LOG_REPORT_REQUEST,
  ENQUIRY_LOG_REPORT_REQUEST_RECEIVED,
  ENQUIRY_LOG_REPORT_UNLOAD,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR,
  MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED,
  MONTHLY_EVENT_VALUES_REPORT_UNLOAD,
  SNAPSHOT_REPORT_REQUEST,
  SNAPSHOT_REPORT_REQUEST_ERROR,
  SNAPSHOT_REPORT_REQUEST_RECEIVED,
  SNAPSHOT_REPORT_UNLOAD,
  SPEND_REPORT_REQUEST,
  SPEND_REPORT_REQUEST_ERROR,
  SPEND_REPORT_REQUEST_RECEIVED,
  SPEND_REPORT_UNLOAD,
} from "./types";

export const enquiryLogReport = (state = {
  enquiryLogReport: [],
  error: false,
  isFetchingEnquiryLog: false,
  enquiryLogPage: 1,
  enquiryLogLastPage: 1,
  errorData: [],
  enquiryLogTotal: 0
}, action) => {
  switch (action.type) {
    case ENQUIRY_LOG_REPORT_REQUEST:
      return {
        ...state,
        isFetchingEnquiryLog: true,
        errorData: [],
      }
    case ENQUIRY_LOG_REPORT_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingEnquiryLog: false,
        error: false,
        enquiryLogReport: action.data.data,
        enquiryLogPage: action.data.meta.current_page,
        enquiryLogLastPage: action.data.meta.last_page,
        enquiryLogTotal: action.data.meta.total,
        errorData: [],
      }
    case ENQUIRY_LOG_REPORT_ERROR:
      return {
        ...state,
        isFetchingEnquiryLog: false,
        error: true,
        errorData: action.error?.data
      }
    case ENQUIRY_LOG_REPORT_UNLOAD:
      return {
        ...state,
        enquiryLogReport: [],
        errorData: [],
        error: false,
        isFetchingEnquiryLog: false,
        enquiryLogPage: 1,
        enquiryLogLastPage: 1,
        enquiryLogTotal: 0
      }
    default:
      return state;
  }
}

export const snapshotReport = (state = {
  snapshotReport: [],
  error: false,
  isFetchingSnapshotReport: false,
  errorData: [],
}, action) => {
  switch (action.type) {
    case SNAPSHOT_REPORT_REQUEST:
      return {
        ...state,
        error: false,
        snapshotReport: [],
        isFetchingSnapshotReport: true
      }
    case SNAPSHOT_REPORT_REQUEST_RECEIVED:
      return {
        ...state,
        error: false,
        snapshotReport: action.data.data,
        isFetchingSnapshotReport: false
      }
    case SNAPSHOT_REPORT_REQUEST_ERROR:
      return {
        ...state,
        error: true,
        isFetchingSnapshotReport: false
      }
    case SNAPSHOT_REPORT_UNLOAD:
      return {
        ...state,
        snapshotReport: [],
        error: false,
        isFetchingSnapshotReport: false
      }
    default:
      return state;
  }
}

export const spendReport = (state = {
  spendReport: [],
  isLoadingSpendReport: false,
  error: true,
  errorData: [],
}, action) => {
  switch (action.type) {
    case SPEND_REPORT_REQUEST:
      return {
        ...state,
        isLoadingSpendReport: true
      }
    case SPEND_REPORT_REQUEST_RECEIVED:
      return {
        ...state,
        isLoadingSpendReport: false,
        spendReport: action.data.data,
        error: false
      }
    case SPEND_REPORT_REQUEST_ERROR:
      return {
        ...state,
        isLoadingSpendReport: false,
        spendReport: [],
        error: true,
        errorData: action.error?.data
      }
    case SPEND_REPORT_UNLOAD:
      return {
        ...state,
        spendReport: [],
        errorData: [],
        isLoadingSpendReport: false,
        error: false
      }
    default:
      return state;
  }
}

export const monthlyEventValuesReport = (state = {
  monthlyEventValuesReport: [],
  isFetchingYearlyReport: false,
  error: false
}, action) => {
  switch (action.type) {
    case MONTHLY_EVENT_VALUES_REPORT_REQUEST:
      return {
        ...state,
        isFetchingYearlyReport: true
      }
    case MONTHLY_EVENT_VALUES_REPORT_REQUEST_RECEIVED:
      return {
        ...state,
        monthlyEventValuesReport: action.data.data,
        isFetchingYearlyReport: false,
      }
    case MONTHLY_EVENT_VALUES_REPORT_REQUEST_ERROR:
      return {
        ...state,
        isFetchingYearlyReport: false,
        error: true,
        monthlyEventValuesReport: []
      }
    case MONTHLY_EVENT_VALUES_REPORT_UNLOAD:
      return {
        ...state,
        isFetchingYearlyReport: false,
        monthlyEventValuesReport: [],
        error: false
      }
    default:
      return state;
  }
}

export const cashFlowReport = (state = {
  cashFlowReport: [],
  cashFlowReportError: false,
  isFetchingCashFlowReport: false,
}, action) => {
  switch (action.type) {
    case CASH_FLOW_REPORT_REQUEST:
      return {
        ...state,
        isFetchingCashFlowReport: true
      }
    case CASH_FLOW_REPORT_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingCashFlowReport: false,
        cashFlowReport: action?.data,
        cashFlowReportError: false,
      }
    case CASH_FLOW_REPORT_REQUEST_ERROR:
      return {
        ...state,
        isFetchingCashFlowReport: false,
        cashFlowReport: [],
        cashFlowReportError: action?.error?.data
      }
    case CASH_FLOW_REPORT_UNLOAD:
      return {
        ...state,
        isFetchingCashFlowReport: false,
        cashFlowReport: [],
        cashFlowReportError: false,
      }
    default: return state
  }
}