import * as CORPORATE_ROUTES from "../../../Router/corporate_routes";
import React from "react";
let UnapprovedAgency = ({userAgency }) => {
    return (
        <div className='bg-primary px-3 py-6 my-5 block font-bold text-white'>
            <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                {'Hold tight - we are just approving your new agency. Once we\'ve done that, you\'ll be able to log in to view your RFPs, manage your companies and invite other agency users. This won\'t take long. We will drop you an email shortly.'}
            </p>
            <p className={'mt-2'}>
                In the meantime why not explore the site, read some of the blogs and get familar with the how to guides - accessible through the live chat search.
           </p>
        </div>
    )
}

export default UnapprovedAgency;