import React from "react";
import {useLocation} from 'react-router-dom';
import { connect } from "react-redux";
import { userId} from "../modules/auth";
function NotFound({children, userId}) {

    const location = useLocation()
    return (
        <div className="py-8 container">
            <h1 className="text-3xl mb-5">404</h1>
            {children}
            <p><small>ref: {location.pathname + location.search} | {userId ?? '-'}</small></p>
        </div>
    );
}


export default connect((state => ({
    userId: userId(state)
})))(NotFound);
