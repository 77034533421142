import { INVOICE_PDFS_RECEIVED_ERROR, INVOICE_PDFS_RECEIVED_REQUEST, INVOICE_PDFS_REQUEST } from "./types";

export const invoicePdfs = (state = {
  invoicePdfs: [],
  isLoadingInvoicePdfs: false,
  error: null,
}, action) => {
  switch (action.type) {
    case INVOICE_PDFS_REQUEST:
      return {
        ...state,
        isLoadingInvoicePdfs: true,
      };
    case INVOICE_PDFS_RECEIVED_REQUEST:
      return {
        ...state,
        isLoadingInvoicePdfs: false,
        invoicePdfs: action.data.data || [],
      };
    case INVOICE_PDFS_RECEIVED_ERROR:
      return {
        ...state,
        invoicePdfs: [],
        error: action.error,
        isLoadingInvoicePdfs: false,
      };
    default:
      return state;
  }
};